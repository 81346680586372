import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link as Lunk, useLocation } from 'react-router-dom';
import { Link, Button, IconButton, Toolbar, AppBar, Menu, MenuItem } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import Countdown from './countdown';
import { useAuth } from "../auth";
import { useContext } from "react";
import { PlayerStatusContext } from "./playerStatusContext";
import { GameStatusContext } from "./gameStatusContext";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    bar: {
        backgroundColor: theme.palette.background.default,
    },
    title: {
        flexGrow: 1,
        color: 'white',
        fontSize: '36px',
        fontFamily: '"Comic Sans MS", "Comic Sans", "Chalkboard SE", "Comic Neue", sans-serif',
    },
}));

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function TopBar(props) {
    const classes = useStyles();
    const width = useWindowSize();
    const location = useLocation();
    const {isLoggedIn, logout, forceLogin} = useAuth();
    const { isAlive, isRegistered } = useContext(PlayerStatusContext);
    const { gameStatus } = useContext(GameStatusContext);
    

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
    };
    const handleOpen2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = (event) => {
        setAnchorEl2(null);
        setAnchorEl(null);
    };
    
    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0} className={classes.bar}>
                <Toolbar>
                <Link underline='none' component={Lunk} to="/" className={classes.title} >
                    CWRU HvZ
                </Link>
                { width >= 1343 && 
                    <div>
                        {isLoggedIn() ? 
                            <span>
                                {isRegistered &&
                                    <Button component={Lunk} to="/report" color="inherit">
                                        {isAlive ? "Redeem Code" : "Report Tag"}
                                    </Button>
                                }
                                {!isRegistered && gameStatus === 'preGame' &&
                                    <Button component={Lunk} to="/register" color="inherit">
                                        Register
                                    </Button>
                                }
                                
                                <Button component={Lunk} to="/profile" color="inherit">My Profile</Button>
                            </span>
                            :
                            <Button onClick={() => forceLogin(location.pathname !== '/' ? location.pathname : '/home')} color="inherit">Log In</Button>
                        }
                        <Button component={Lunk} to="/scoreboard" color="inherit">Scoreboard</Button>
                        <Button component={Lunk} to="/missions" color="inherit">Missions</Button>
                        <Button component={Lunk} to="/squads" color="inherit">Squads</Button>
                        <Button onClick={handleOpen2} color="inherit">Official Documents</Button>
                        <Menu 
                            id="simple-menu"
                            anchorEl={anchorEl2}
                            keepMounted
                            open={Boolean(anchorEl2)}
                            onClose={handleClose2}>
                            <MenuItem onClick={handleClose2} component={Lunk} to="/rules">HvZ Rules</MenuItem>
                            <MenuItem onClick={handleClose2} component={Lunk} to="/rulesPresentation">HvZ Rules Presentation</MenuItem>
                            <MenuItem onClick={handleClose2} component={Lunk} to="/bylaws">HvZ Bylaws</MenuItem>
                            <MenuItem onClick={handleClose2} component={Lunk} to="/constitution">BGC Constitution</MenuItem>
                            <MenuItem onClick={handleClose2}>
                                <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://docs.google.com/spreadsheets/d/10gH9vVnkVrY0KNtVYeRJXuE_PEPQlUAJmX127kiV3DA/edit?usp=sharing">Approved Blaster List</a>
                            </MenuItem>
                            <MenuItem onClick={handleClose2}>
                                <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://www.google.com/maps/d/embed?mid=1rg7SSg5hAx9cj5vJdlKxGYEdcWLF71c&ehbc=2E312F">Boundaries Map</a>

                            </MenuItem>
                        </Menu>
                        <Button component={Lunk} to="/contact" color="inherit">Contact Admin</Button>
                        {isLoggedIn() &&
                            <Button onClick={() => logout()} color="inherit">Log out</Button>
                        }
                    </div>
                }
                <Countdown className={classes.thing} />
                { width < 1343 &&
                    <div>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            { isLoggedIn() ?
                                <span>
                                    {isRegistered &&
                                        <MenuItem onClick={handleClose} component={Lunk} to="/report">
                                            {isAlive ? "Redeem Code" : "Report Tag"}
                                        </MenuItem>
                                    }
                                    {!isRegistered && gameStatus === 'preGame' &&
                                        <MenuItem onClick={handleClose} component={Lunk} to="/register">
                                            Register
                                        </MenuItem>
                                    }
                                    
                                    <MenuItem onClick={handleClose} component={Lunk} to='/profile'>My Profile</MenuItem>
                                </span>
                                :
                                <MenuItem onClick={() => {handleClose(); forceLogin(location.pathname !== '/' ? location.pathname : '/home')}}>Log in</MenuItem>
                            }
                            
                            <MenuItem onClick={handleClose} component={Lunk} to="/scoreboard">Scoreboard</MenuItem>
                            <MenuItem onClick={handleClose} component={Lunk} to="/missions">Missions</MenuItem>
                            <MenuItem onClick={handleClose} component={Lunk} to="/squads">Squads</MenuItem>
                            <MenuItem onClick={handleOpen2}>Official Documents</MenuItem>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl2}
                                keepMounted
                                open={Boolean(anchorEl2)}
                                onClose={handleClose2}>
                                    <MenuItem onClick={handleClose2} component={Lunk} to="/rules">HvZ Rules</MenuItem>
                                    <MenuItem onClick={handleClose2} component={Lunk} to="/rulesPresentation">HvZ Rules Presentation</MenuItem>
                                    <MenuItem onClick={handleClose2} component={Lunk} to="/bylaws">HvZ Bylaws</MenuItem>
                                    <MenuItem onClick={handleClose2} component={Lunk} to="/constitution">BGC Constitution</MenuItem>
                                    <MenuItem onClick={handleClose2}>
                                        <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://docs.google.com/spreadsheets/d/10gH9vVnkVrY0KNtVYeRJXuE_PEPQlUAJmX127kiV3DA/edit?usp=sharing">Approved Blaster List</a>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose2}>
                                        <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://www.google.com/maps/d/embed?mid=1rg7SSg5hAx9cj5vJdlKxGYEdcWLF71c&ehbc=2E312F">Boundaries Map</a>
                                    </MenuItem>
                            </Menu>
                            <MenuItem onClick={handleClose} component={Lunk} to="/contact">Contact Admin</MenuItem>
                            {isLoggedIn() &&
                                <MenuItem onClick={() => {handleClose(); logout()}}>Log out</MenuItem>
                            }
                        </Menu>
                    </div>
                }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default TopBar;
